import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyConfig, FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { CookieService } from 'ngx-cookie-service';
import { BasicPopupComponent } from '../shared/popups/basic-popup/basic-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { CaptureImageFromCameraComponent } from '../shared/popups/capture-image-from-camera/capture-image-from-camera.component';
import { timestamp } from 'rxjs';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-site-visit-inspection',
  templateUrl: './site-visit-inspection.component.html',
  styleUrls: ['./site-visit-inspection.component.css']
})
export class SiteVisitInspectionComponent implements OnInit  {

  form = new FormGroup({});
  model: any = {
    cannabis_business_type: [],
    is_cannabis_inspection: null,
    iConfirm: false
  };

  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];
  masterFields: FormlyFieldConfig[] = [];
  public lat;
  public lng;
  zoom;
  rowData: any;
  site_visit_id: string;
  selectedFile: any;
  clickedPhoto: File;
  finName: string;
  version_no: any;

  //Camera
  @ViewChild('video', { static: true }) video!: ElementRef<HTMLVideoElement>;
  @ViewChild('canvas', { static: true }) canvas!: ElementRef<HTMLCanvasElement>;

  captures: string[] = [];
  isFrontCamera: boolean = true;
  stream: MediaStream | null = null;
  capturedImage: string;
  capturedPhotoFromModal: any;
  uploadedSignature: any;
  removedPanel: boolean = false;
  newJson: any;
  site_visit_images: any;

  constructor(
    public _api: ApiService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) {
      const navigation = this.router.getCurrentNavigation();
      this.rowData = navigation?.extras.state?.['data'];
      console.log('--------------this row data', this.rowData);
  }

ngOnInit(): void {
  this.route.paramMap.subscribe(params => {
    this.site_visit_id = params.get('id');
   this.getHeaderFooter();
    this.getSiteVisitForm();
    this.getAllDocumentBySiteVisitId();
  });

  this.form.valueChanges.subscribe(updatedValue => {
    console.log('Form updated:', updatedValue);
    this.model.iConfirm = updatedValue['certifications']['iconfirm'];
  });
}
getHeaderFooter(){
  const apiData = {
    site_visit_id: this.site_visit_id,
    latitude: this.cookieService.get('latitude'),
    longitude:  this.cookieService.get('longitude')
  };
  this.spinner.show();
  this._api.functionPOST('web/siteVisit/getHeaderFooter', apiData).subscribe((response) => {
    const logoURL = response['data']['static_base_url']+'/'+response['data']['web_hf']['logo_path']+`?timestamp=${new Date().getTime()}`;
    const faviconURL = response['data']['static_base_url']+'/'+response['data']['web_hf']['favicon_path']+`?timestamp=${new Date().getTime()}`;
    const headerContent = response['data']['web_hf']['header'];
    const footerContent = response['data']['web_hf']['footer'];
    const businessURL = response['data']['web_hf']['provider_web_url'];
    const highLightColor = response['data']['web_hf']['highlight_color'];
    document.documentElement.style.setProperty('--dynamic-bg-color', highLightColor);
    document.documentElement.style.setProperty('--dynamic-section-bg-color', highLightColor);

    this.setFavicon(faviconURL);
    this.setLogo(logoURL);
    this.setHeader(headerContent);
    this.setFooter(footerContent);
    this.setProviderWebURL(businessURL);
  });
  this.spinner.hide();
}

setLogo(url: string): void {
  const imgElement = document.getElementById('dynamicLogo') as HTMLImageElement;
  if (imgElement) {
      imgElement.src = url;
      imgElement.alt = 'Logo';
  }
}

setFavicon(url: string): void {
  const favicon = document.getElementById('dynamicFavicon') as HTMLLinkElement;
  if (favicon) {
    favicon.href = url;
  }
}

setHeader(header: string): void {
  const headerTitle = document.getElementById('dynamicHeader');
  if (headerTitle) {
    headerTitle.innerHTML = header;
  }
}

setFooter(footer: string): void {
  const footerTitle = document.getElementById('dynamicFooter');
  if (footerTitle) {
    footerTitle.innerHTML = footer;
  }
}

setProviderWebURL(web_url: string): void {
  const webURL = document.getElementById('providerWebURL') as HTMLLinkElement;;
  if (webURL) {
    webURL.href = web_url;
  }
}
updateFieldRequired(fields: FormlyFieldConfig[], key: string, required: boolean): void {
  fields.forEach(field => {
    if (field.key === key) {
      if (field.templateOptions) {
        field.templateOptions.required = required;
      }
    }

    if (field.fieldGroup) {
      this.updateFieldRequired(field.fieldGroup, key, required);
    }
  });
}

functionMap = {
  capturePhoto: this.capturePhoto.bind(this),
  uploadSiteVisitImage: this.uploadSiteVisitImage.bind(this),
  viewFullImage: this.viewFullImage.bind(this),
  deleteThumbnail: this.deleteThumbnail.bind(this),
  openPopUpForCaptureImg: this.openPopUpForCaptureImg.bind(this),
  openPopUpForCaptureImgGC: this.openPopUpForCaptureImgGC.bind(this),
  openPopUpForCaptureImgSL: this.openPopUpForCaptureImgSL.bind(this),
  openPopUpForCaptureImgNL: this.openPopUpForCaptureImgNL.bind(this),
  onSelectionChange: this.onSelectionChange.bind(this),
  uploadSignedSignature: this.uploadSignedSignature.bind(this),
}

bindFunctionsToTemplateOptions(fields: FormlyFieldConfig[]) {
  fields.forEach(field => {
    if (field.fieldGroup) {
      this.bindFunctionsToTemplateOptions(field.fieldGroup);
    } else if (field.templateOptions && field.templateOptions.onClick) {
      const functionName = field.templateOptions.onClick;
      if (this.functionMap[functionName]) {
        field.templateOptions.onClick = this.functionMap[functionName];
      }
    }else if (field.templateOptions && field.templateOptions.onCapture) {
      const functionName = field.templateOptions.onCapture;
      if (this.functionMap[functionName]) {
        field.templateOptions.onCapture = this.functionMap[functionName];
      }
    }else if (field.templateOptions && field.templateOptions.onDelete) {
    const functionName = field.templateOptions.onDelete;
    if (this.functionMap[functionName]) {
      field.templateOptions.onDelete = this.functionMap[functionName];
    }
  }
  else if (field.templateOptions && field.templateOptions.selectionChange) {
    const functionName = field.templateOptions.selectionChange;
    if (this.functionMap[functionName]) {
      field.templateOptions.selectionChange = this.functionMap[functionName];
    }
  }else if (field.templateOptions && field.templateOptions.changeFunction) {
      const functionName = field.templateOptions.changeFunction;
      if (this.functionMap[functionName]) {
        field.templateOptions.change = this.functionMap[functionName];
      }
    }
  });
}

bindExpressionProperties(fields: FormlyFieldConfig[]) {
  return fields.map(field => {
    if (field.expressionProperties) {
      Object.keys(field.expressionProperties).forEach(key => {
        const functionName = field.expressionProperties[key] as string;
        if (this.functionMap[functionName]) {
          field.expressionProperties[key] = this.functionMap[functionName];
        }
      });
    }
    if (field.fieldGroup) {
      field.fieldGroup = this.bindExpressionProperties(field.fieldGroup);
    }
    return field;
  });
}

findFieldByKey(fields: FormlyFieldConfig[], key: string): FormlyFieldConfig | null {
  for (const field of fields) {
    if (field.key === key) {
      return field;
    }
    if (field.fieldGroup && field.fieldGroup.length) {
      const nestedField = this.findFieldByKey(field.fieldGroup, key);
      if (nestedField) {
        return nestedField;
      }
    }
  }
  return null;
}

uploadSignedSignature(signature: string) {
  this.getAllDocumentBySiteVisitId();
}

getSiteVisitForm(){
  const apiData = {
    site_visit_id: this.site_visit_id,
    latitude: this.cookieService.get('latitude'),
    longitude:  this.cookieService.get('longitude')
  };
  this.spinner.show();
  this._api.functionPOST('web/getSiteVisitApplication', apiData).subscribe((response) => {
    this.finName = response['data']['site_visit']['sp_bp_business_name'];
    this.version_no = response['data']['application']['version_no'];
    this.model.is_cannabis_inspection = response['data']['site_visit']['is_cannabis_inspection'];

    this.masterFields = response['data']['application']['form_json'];
   
    this.fields = cloneDeep(this.masterFields);

    this.cdr.detectChanges();
    this.bindFunctionsToTemplateOptions(this.fields);
    this.fields = this.bindExpressionProperties(this.fields);
    this.updateObservationsLabel(this.finName);
    this.updateCertificationDescription();

    const locationInformationAccordion = this.findFieldByKey(this.fields, "locationInformation");
    if (locationInformationAccordion) {
      const locationInformationField = this.findFieldByKey(locationInformationAccordion.fieldGroup, "location_thumbnails");
      if (locationInformationField) {
        locationInformationField.templateOptions = {
          ...locationInformationField.templateOptions,
          onDelete: this[locationInformationField.templateOptions.onDelete].bind(this),
        };
      }
    }
    const businessInformationAccordion = this.findFieldByKey(this.fields, "cannabis_businessInformation");
    if (businessInformationAccordion) {
      const businessInformationField = this.findFieldByKey(businessInformationAccordion.fieldGroup, "cannabis_business_type");
      if (businessInformationField) {
        businessInformationField.templateOptions = {
          ...businessInformationField.templateOptions,
          OnChange: this[businessInformationField.templateOptions.OnChange].bind(this),
        };
      }
    }
    const general_cannabisAccordion = this.findFieldByKey(this.fields, "general_cannabis");
    if (general_cannabisAccordion) {
      const sales_tax_permitField = this.findFieldByKey(general_cannabisAccordion.fieldGroup, "sales_tax_permit_thumbnails");
      if (sales_tax_permitField) {
        sales_tax_permitField.templateOptions = {
          ...sales_tax_permitField.templateOptions,
          onDelete: this[sales_tax_permitField.templateOptions.onDelete].bind(this),
        };
      }
    }
    const cultivationAccordion = this.findFieldByKey(this.fields, "cultivation");
    if (cultivationAccordion) {
      const cultivationField = this.findFieldByKey(cultivationAccordion.fieldGroup, "seperate_seed_license_thumbnails");
      if (cultivationField) {
        cultivationField.templateOptions = {
          ...cultivationField.templateOptions,
          onDelete: this[cultivationField.templateOptions.onDelete].bind(this),
        };
      }
    }

    const seperate_nursery_licenseAccordion = this.findFieldByKey(this.fields, "cultivation");
    if (seperate_nursery_licenseAccordion) {
      const cseperate_nursery_licenseField = this.findFieldByKey(seperate_nursery_licenseAccordion.fieldGroup, "seperate_nursery_license_thumbnails");
      if (cseperate_nursery_licenseField) {
        cseperate_nursery_licenseField.templateOptions = {
          ...cseperate_nursery_licenseField.templateOptions,
          onDelete: this[cseperate_nursery_licenseField.templateOptions.onDelete].bind(this),
        };
      }
    }
    const non_can_business = this.findFieldByKey(this.fields, "non_cannabis_businessInformation");
    const can_business = this.findFieldByKey(this.fields, "businessInformation");
    if(this.model.is_cannabis_inspection === 'Y'){
      if (can_business) {
        can_business.templateOptions.expanded = true;
      }

      this.fields = this.fields.map(panel => {
        if (panel.fieldGroup) {
          panel.fieldGroup = panel.fieldGroup.filter(subPanel => ['cannabis','both'].includes(subPanel.templateOptions.description.toString()));
        }
        return panel;
      });
    }else{
      
      if (non_can_business) {
        non_can_business.templateOptions.expanded = true;
      }

      this.fields = this.fields.map(panel => {
        if (panel.fieldGroup) {
          panel.fieldGroup = panel.fieldGroup.filter(subPanel => ['non_cannabis','both'].includes(subPanel.templateOptions.description.toString()));
        }
        return panel;
      });
     
    }
    this.fields = this.fields.map(panel => {
      if (panel.fieldGroup) {
        panel.fieldGroup = panel.fieldGroup.filter(subPanel => !['dispensary_information', 'cultivation', 'manufacturing_production', 'distribution'].includes(subPanel.key.toString()));
      }
      
      return panel;
    }).filter(panel => {
      return !panel.fieldGroup || panel.fieldGroup.length > 0;
    });
    
    this.businessInfoRequiredCondition();
    this.cdr.detectChanges();
     this.spinner.hide();
  });   
}

businessInfoRequiredCondition() {
  const non_can_business = this.findFieldByKey(this.fields, 'non_cannabis_purpose_of_the_business');
  const can_business = this.findFieldByKey(this.fields, 'cannabis_business_type');
  if(this.model.is_cannabis_inspection === 'Y'){
    // this.cannabisLicenseRequired(true, ['cannabis_license', 'general_cannabis']);
    can_business.templateOptions.required = true;
    //non_can_business.templateOptions.required = false;
    
  }else  if(this.model.is_cannabis_inspection === 'N'){
    // this.cannabisLicenseRequired(true, ['cannabis_license', 'general_cannabis']);
    //can_business.templateOptions.required = true;
    non_can_business.templateOptions.required = false;
  }else{
    can_business.templateOptions.required = false;
    non_can_business.templateOptions.required = true;
    this.removeRequiredField('openPopUpForCaptureImgGC');
    this.removeRequiredField('sales_tax_permit_thumbnails');
    this.cannabisLicenseRequired(false, ['cannabis_license', 'general_cannabis']);
    // this.removeRequiredField('openPopUpForCaptureImgSL');
    // this.removeRequiredField('seperate_seed_license_thumbnails');
    // this.removeRequiredField('openPopUpForCaptureImgNL');
    // this.removeRequiredField('seperate_nursery_license_thumbnails');
  }
}

removeRequiredField(key: string) {
  // Iterate over the fields to find the target field by key
  this.fields = this.fields.map(field => {
    if (field.key === key) {
      // Remove the required property from the templateOptions
      if (field.templateOptions?.required) {
        delete field.templateOptions.required;
      }
    }
    
    // Recursively check nested fields if the field has a fieldGroup
    if (field.fieldGroup && field.fieldGroup.length > 0) {
      field.fieldGroup = field.fieldGroup.map(subField => {
        if (subField.key === key && subField.templateOptions?.required) {
          delete subField.templateOptions.required;
        }
        return subField;
      });
    }

    return field;
  });
}

cannabisLicenseRequired(isRequired: boolean, business_type ) {
  business_type.forEach(type => {
    const employeesSection = this.findFieldByKey(this.fields, type)
    if (employeesSection?.fieldGroup) {
        employeesSection.fieldGroup.forEach(field => {
          field.templateOptions.required = isRequired;
        });
      }
  });
}

updateObservationsLabel(fin_name) {
  const observationsAccordion = this.findFieldByKey(this.fields, "observations");
  if (observationsAccordion) {
    const observationsField = this.findFieldByKey(observationsAccordion.fieldGroup, "observations");
    if (observationsField) {
      observationsField.templateOptions.label = `Enter any comments or observations to share with ${fin_name}`;
      this.fields = [...this.fields];
    }
  }
}

updateCertificationDescription() {
  const certificationsAccordion = this.findFieldByKey(this.fields, "certifications");
  if (certificationsAccordion) {
    const certificationsField = this.findFieldByKey(certificationsAccordion.fieldGroup, "certifications");
    if (certificationsField) {
      certificationsField.templateOptions.description = `${this.site_visit_id}`;
      this.fields = [...this.fields];
    }
  }
}

capturePhoto(field: any) {
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = 'image/*';
  input.capture = 'camera';
  input.onchange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const photoData = reader.result;
        const timestamp = new Date().toISOString();

        this.model.photoMetadata = this.model.photoMetadata || {};
        this.model.photoMetadata.photo = photoData;

        this.model.locationInformation = this.model.locationInformation || {};
        this.model.locationInformation.photo = photoData;

        const previewField = this.findFieldByKey(this.fields, 'photoPreview');
        if (previewField) {
          previewField.templateOptions = { ...previewField.templateOptions, src: photoData };
        }

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const geoLocation = {
                latitude: position.coords.latitude.toFixed(6),
                longitude: position.coords.longitude.toFixed(6)
              };
              field.form.patchValue({
                photo: photoData,
                timestamp,
                geoLocation
              });

              this.model.latitude = geoLocation.latitude;
              this.model.longitude = geoLocation.longitude;
              this.model.timestamp = timestamp;
              this.convertToBlob(file);
              
            },
            (error) => {
              field.form.patchValue({
                photo: photoData,
                timestamp,
                geoLocation: { latitude: 'N/A', longitude: 'N/A' }
              });
            }
          );
        } else {
          field.form.patchValue({
            photo: photoData,
            timestamp,
            geoLocation: { latitude: 'N/A', longitude: 'N/A' }
          });
        }

        const uploadButtonField = this.findFieldByKey(this.fields, 'uploadButton');
        if (photoData && uploadButtonField) {
          uploadButtonField.hide = false;
        }

        const retakeButtonField = this.findFieldByKey(this.fields, 'retakeButton');
        if (photoData && retakeButtonField) {
          retakeButtonField.hide = false;
        }

        const timestampField = this.findFieldByKey(this.fields, 'timestamp');
        if (photoData && timestampField) {
          // timestampField.hide = false;
        }
        const latitudeField = this.findFieldByKey(this.fields, 'latitude');
        if (photoData && latitudeField) {
          // latitudeField.hide = false;
        }
        const longitudeField = this.findFieldByKey(this.fields, 'longitude');
        if (photoData && longitudeField) {
          // longitudeField.hide = false;
        }
      };
      reader.readAsDataURL(file);
    }
  };
  input.click();
}

convertToBlob(file: File) {
  const reader = new FileReader();
  reader.onload = () => {
    const arrayBuffer = reader.result as ArrayBuffer;
    const blob = new Blob([new Uint8Array(arrayBuffer)], { type: file.type });
    this.clickedPhoto = new File([blob], file.name, { type: file.type });
  };
  reader.readAsArrayBuffer(file);
}

filterPanel(masterFields, allSelectedBusiness){
const masterJson = cloneDeep(masterFields);
const excludeKeys = ['dispensary_information', 'cultivation', 'manufacturing_production', 'distribution'];
const keyMapping: { [key: string]: string } = {
  'Cannabis - Dispensary / Retail': 'dispensary_information',
  'Cannabis - Cultivation': 'cultivation',
  'Cannabis - Manufacturing / Production': 'manufacturing_production', 
  'Cannabis - Distribution': 'distribution'
};

const keysToRemove = allSelectedBusiness
  .map(value => keyMapping[value])
  .filter(key => key); 
const updatedExcludeKeys = excludeKeys.filter(key => !keysToRemove.includes(key));
const includeKeys = allSelectedBusiness;
this.newJson = masterJson.map(panel => {
if (panel.fieldGroup) {
  panel.fieldGroup = panel.fieldGroup.filter(subPanel => {
    return includeKeys.includes(subPanel.key) || !updatedExcludeKeys.includes(subPanel.key.toString());
  });
}
if (includeKeys.includes(panel.key) || panel.fieldGroup?.length) {
  return panel;
}
return null;
}).filter(Boolean);
return this.newJson
}

onSelectionChange(field, event) {
  if(field.formControl.value){
    this.model.cannabis_business_type = field.formControl.value;
  }
      const selectedValues = Array.isArray(field.formControl.value) 
      ? field.formControl.value 
      : [field.formControl.value];

    const selectedBusinessTypes = [
      'Cannabis - Dispensary / Retail', 
      'Cannabis - Cultivation', 
      'Cannabis - Manufacturing / Production', 
      'Cannabis - Distribution'
    ];
    const isMatch = selectedBusinessTypes.some(value => 
      selectedValues.includes(value)
    );

  if (isMatch) {

    const masterJson = cloneDeep(this.masterFields);
    let filtermaterjson;
    if(this.model.is_cannabis_inspection === 'Y'){
      filtermaterjson = masterJson.map(panel => {
        if (panel.fieldGroup) {
          panel.fieldGroup = panel.fieldGroup.filter(subPanel => ['cannabis','both'].includes(subPanel.templateOptions.description.toString()));
        }
        
        return panel;
      });
    }
    else{

      filtermaterjson = masterJson.map(panel => {
        if (panel.fieldGroup) {
          panel.fieldGroup = panel.fieldGroup.filter(subPanel => ['non_cannabis','both'].includes(subPanel.templateOptions.description.toString()));
        }
        return panel;
      });
    }
      this.fields = this.filterPanel(filtermaterjson, selectedValues)
    this.cdr.detectChanges();

      this.bindFunctionsToTemplateOptions(this.fields);
      this.fields = this.bindExpressionProperties(this.fields);
      this.updateObservationsLabel(this.finName);
      this.updateCertificationDescription();

      const businessInformationAccordion = this.findFieldByKey(this.fields, "cannabis_businessInformation");
      if (businessInformationAccordion) {
        const businessInformationField = this.findFieldByKey(businessInformationAccordion.fieldGroup, "cannabis_business_type");
        if (businessInformationField) {
          businessInformationField.templateOptions = {
            ...businessInformationField.templateOptions,
            OnChange: this[businessInformationField.templateOptions.OnChange].bind(this),
          };
        }
      }
      if(this.model.cannabis_business_type === 'Y'){
        const general_cannabisAccordion1 = this.findFieldByKey(this.fields, "businessInformation");
        if (general_cannabisAccordion1) {
          general_cannabisAccordion1.hide = false
        }
        const general_cannabisAccordion2 = this.findFieldByKey(this.fields, "non_cannabis_businessInformation");
        if (general_cannabisAccordion2) {
          general_cannabisAccordion2.hide = true
        }
      }
      if(this.model.cannabis_business_type === 'N'){
        const general_cannabisAccordion1 = this.findFieldByKey(this.fields, "businessInformation");
        if (general_cannabisAccordion1) {
          general_cannabisAccordion1.hide = true
        }
        const general_cannabisAccordion2 = this.findFieldByKey(this.fields, "non_cannabis_businessInformation");
        if (general_cannabisAccordion2) {
          general_cannabisAccordion2.hide = false
        }
      }
      const cultivationAccordion = this.findFieldByKey(this.fields, "cultivation");
    if (cultivationAccordion) {
      const cultivationField = this.findFieldByKey(cultivationAccordion.fieldGroup, "seperate_seed_license_thumbnails");
      if (cultivationField) {
        cultivationField.templateOptions = {
          ...cultivationField.templateOptions,
          onDelete: this[cultivationField.templateOptions.onDelete].bind(this),
        };
      }
    }
    const seperate_nursery_licenseAccordion = this.findFieldByKey(this.fields, "cultivation");
    if (seperate_nursery_licenseAccordion) {
      const cseperate_nursery_licenseField = this.findFieldByKey(seperate_nursery_licenseAccordion.fieldGroup, "seperate_nursery_license_thumbnails");
      if (cseperate_nursery_licenseField) {
        cseperate_nursery_licenseField.templateOptions = {
          ...cseperate_nursery_licenseField.templateOptions,
          onDelete: this[cseperate_nursery_licenseField.templateOptions.onDelete].bind(this),
        };
      }
    }
      this.cannabisLicenseRequired(true, selectedValues);
      this.businessInfoRequiredCondition();
      
  }else{

    this.fields = this.fields.map(panel => {
      if (panel.fieldGroup) {
        panel.fieldGroup = panel.fieldGroup.filter(subPanel => !['dispensary_information', 'cultivation', 'manufacturing_production', 'distribution'].includes(subPanel.key.toString()));
      }
      return panel;
    });
    this.cannabisLicenseRequired(false, selectedValues);
  }
  this.getAllDocumentBySiteVisitId();
}

onSubmit(model: any) {
  const isLocationImages =  this.site_visit_images?.location_image?.df_sitevisit_attachments
  if(!isLocationImages || isLocationImages.length===0){
    this._api.openSnackBar('Location Images is not uploaded', 'Error');
    return;
  }
  const isSalesTasxPermit =  this.site_visit_images?.general_cannabis?.df_sitevisit_attachments
  if(model.general_cannabis?.sales_tax_permit==='yes'){
    if(!isSalesTasxPermit || isSalesTasxPermit.length===0){
      this._api.openSnackBar('Sales tax permit license is not uploaded', 'Error');
      return;
    }
  }
  const isSeedLicense =  this.site_visit_images?.cultivation_seed_license?.df_sitevisit_attachments
  if(model.cultivation?.seperate_seed_license==='yes'){
    if(!isSeedLicense || isSeedLicense.length===0){
      this._api.openSnackBar('Seed license is not uploaded', 'Error');
      return;
    }
  }
  const isNurseryLicense =  this.site_visit_images?.cultivation_seperate_nursery?.df_sitevisit_attachments
  if(model.cultivation?.seperate_nursery_license==='yes'){
    if(!isNurseryLicense || isNurseryLicense.length===0){
      this._api.openSnackBar('Nursery license is not uploaded', 'Error');
      return;
    }
  }
  
  const isSignatureUploaded = this.site_visit_images?.officerSignature?.df_sitevisit_attachments;

  // console.log('-------------------isSignatureUploaded', this.site_visit_images?.officerSignature);
  // console.log(
  //   ` !this.form.valid  ${!this.form.valid}, !this.uploadedSignature ${!isSignatureUploaded}, !this.model.iConfirm, ${!this.model.iConfirm}, `)
  if(!this.form.valid  || (!isSignatureUploaded || isSignatureUploaded.length === 0 ) || !this.model.iConfirm){
    this._api.openSnackBar('Complete the mandatory fields or Signature is not uploaded', 'Error');
    return;
  }

  const apiData = {
    site_visit_id: this.site_visit_id,
    latitude: this.cookieService.get('latitude'),
    longitude:  this.cookieService.get('longitude'),
    model_data: model,
    updatedBusinessData: this.rowData['business_data']
  };

  this.spinner.show();
  this._api.functionPOST('web/siteVisit/submitApplication', apiData).subscribe((response) => {
    if (response['success']) {
      this._api.openSnackBar(response['data'], 'Success');
      this.openPopUpForFinalSubmissionMessage();
    }
    this.spinner.hide();
    });
}

resetFields() {
  const latitudeField = this.findFieldByKey(this.fields, 'latitude');
  if (latitudeField) {
    this.model.latitude = null;
    latitudeField.formControl.setValue(null);
    latitudeField.hide = true;
  }
  const longitudeField = this.findFieldByKey(this.fields, 'longitude');
  if (longitudeField) {
    this.model.longitude = null;
    longitudeField.formControl.setValue(null);
    longitudeField.hide = true;
  }
  const timestampField = this.findFieldByKey(this.fields, 'timestamp');
  if (timestampField) {
    this.model.timestamp = null;
    timestampField.formControl.setValue(null);
    timestampField.hide = true;
  }
  const photoMetadata = this.findFieldByKey(this.fields, 'location_photo');
  if (photoMetadata) {
    this.model.location_photo = null;
    photoMetadata.formControl.setValue(null);
  }
  const photoPreviewField = this.findFieldByKey(this.fields, 'photoPreview');
  if (photoPreviewField && photoPreviewField.templateOptions) {
    photoPreviewField.templateOptions.src = '';
  }
  const uploadButtonField = this.findFieldByKey(this.fields, 'uploadButton');
  if (uploadButtonField) {
    uploadButtonField.hide = true;
  }

  const retakeButtonField = this.findFieldByKey(this.fields, 'retakeButton');
  if (retakeButtonField) {
    retakeButtonField.hide = true;
  }
}

findFieldLabelByKey(fields: FormlyFieldConfig[], key: string): string | null {
  for (const field of fields) {
    if (field.key === key) {
      return field.templateOptions?.label || null;
    }
    if (field.fieldGroup && field.fieldGroup.length) {
      const result = this.findFieldLabelByKey(field.fieldGroup, key);
      if (result) {
        return result;
      }
    }
  }
  return null;
}

uploadSiteVisitImage(data?){
  const blob = this.convertDataURLToBlob(this.capturedPhotoFromModal);
  const fileName = `${data['section_type']}_${this.model?.timestamp}.png`;
  const file = new File([blob], fileName, { type: blob.type });
  this.spinner.show();
  const formData = new FormData();
  formData.append('site_visit_id', this.site_visit_id);
  formData.append('doc_type', data['section_type']);
  formData.append('files', file);
  formData.append('img_capture_timestamp', this.model?.timestamp);
  formData.append('latitude', this.model?.latitude);
  formData.append('longitude', this.model?.longitude);
  this.spinner.show();
  this._api.functionPOST('web/uploadSiteVisitImage', formData).subscribe((response) => {
    this._api.openSnackBar(response['data'], 'Success');
    this.clickedPhoto = null;
    this.resetFields();
    this.getAllDocumentBySiteVisitId();
    this.spinner.hide();
  });
}


getAllDocumentBySiteVisitId(){
  const apiData = {
    site_visit_id: this.site_visit_id,
    latitude: this.cookieService.get('latitude'),
    longitude:  this.cookieService.get('longitude')
  };
  this.spinner.show();
  this._api.functionPOST('web/getAllAttachmentsBySiteVisitId', apiData).subscribe((response) => {
    this.site_visit_images=response['data'];

  this.uploadedSignature = response['data']['officerSignature'];

  const certificationsField = this.findFieldByKey(this.fields, 'certifications');
  if(this.uploadedSignature && this.uploadedSignature.length > 0){
    certificationsField.templateOptions.required = false;
  }else{
    certificationsField.templateOptions.required = true;
  }
    const thumbnailsField = this.findFieldByKey(this.fields, 'location_thumbnails');
    if (thumbnailsField && response['data']['location_image']) {
      const currentImages = this.model['location_thumbnails'] || [];
      currentImages.push(response['data']['location_image']['df_sitevisit_attachments']);
      thumbnailsField.templateOptions.images = currentImages[0];
      thumbnailsField.hide = false;
    }
    const sales_tax_permit_thumbnailsField = this.findFieldByKey(this.fields, 'sales_tax_permit_thumbnails');
    if (sales_tax_permit_thumbnailsField && response['data']['general_cannabis']) {
      const currentImages = this.model['sales_tax_permit_thumbnails'] || [];
      currentImages.push(response['data']['general_cannabis']['df_sitevisit_attachments']);
      sales_tax_permit_thumbnailsField.templateOptions.images = currentImages[0];
    }

    const seperate_seed_license_thumbnailsField = this.findFieldByKey(this.fields, 'seperate_seed_license_thumbnails');
    if (seperate_seed_license_thumbnailsField && response['data']['cultivation_seed_license']) {
      const currentImages = this.model['seperate_seed_license_thumbnails'] || [];
      currentImages.push(response['data']['cultivation_seed_license']['df_sitevisit_attachments']);
      seperate_seed_license_thumbnailsField.templateOptions.images = currentImages[0];
    }

    const seperate_nursery_license_thumbnailsField = this.findFieldByKey(this.fields, 'seperate_nursery_license_thumbnails');
    if (seperate_nursery_license_thumbnailsField && response['data']['cultivation_seperate_nursery']) {
      const currentImages = this.model['seperate_nursery_license_thumbnails'] || [];
      currentImages.push(response['data']['cultivation_seperate_nursery']['df_sitevisit_attachments']);
      seperate_nursery_license_thumbnailsField.templateOptions.images = currentImages[0];
    }

    this.spinner.hide();
    });
}

viewFullImage(image){
  const dialogRef = this.dialog.open(BasicPopupComponent, {
    maxWidth: '600px'
    , data: {
      pageTitle: image.attach_name,
      message: image.fileurl,
      pageType: 'FULL_VIEW_IMAGE',
      image_id: image.reference_id,
      site_visit_id: this.site_visit_id
    }
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result) {
     
    }
  });
}

deleteThumbnail(image): void {
  const apiData = {
    site_visit_id: this.site_visit_id,
    df_sitevisit_attachment_id: image['df_sitevisit_attachment_id'],
    latitude: this.cookieService.get('latitude'),
    longitude:  this.cookieService.get('longitude')
  };
  this.spinner.show();
  this._api.functionPOST('web/deleteSiteVisitAttachment', apiData).subscribe((response) => {
    if (response['success']) {
      this._api.openSnackBar(response['data'], 'Success');
      this.getAllDocumentBySiteVisitId();
    }
    this.spinner.hide();
  });
}

openPopUpForCaptureImg(){
  const fieldLabel = this.findFieldLabelByKey(this.fields, 'locationInformation');
  const dialogRef = this.dialog.open(CaptureImageFromCameraComponent, {
    maxWidth: '600px'
    , data: {
      pageTitle: 'Capture Photo',
      message: fieldLabel,
      pageType: 'CAPTURE_PHOTO',
      site_visit_id: this.site_visit_id
    }
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      this.model.latitude = result.latitude;
      this.model.longitude = result.longitude;
      this.model.timestamp = result.timestamp;
      this.capturedPhotoFromModal = result.image;
      this.uploadSiteVisitImage(result);
    }
  });
}

openPopUpForCaptureImgGC(){
  const fieldLabel = this.findFieldLabelByKey(this.fields, 'general_cannabis');
  const dialogRef = this.dialog.open(CaptureImageFromCameraComponent, {
    maxWidth: '600px'
    , data: {
      pageTitle: 'Capture Photo',
      message: fieldLabel,
      pageType: 'CAPTURE_PHOTO',
      site_visit_id: this.site_visit_id
    }
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      this.model.latitude = result.latitude;
      this.model.longitude = result.longitude;
      this.model.timestamp = result.timestamp;
      this.capturedPhotoFromModal = result.image;
      this.uploadSiteVisitImage(result);
    }
  });
}

openPopUpForCaptureImgSL(){
  const fieldLabel = this.findFieldLabelByKey(this.fields, 'cultivation');
  const dialogRef = this.dialog.open(CaptureImageFromCameraComponent, {
    maxWidth: '600px'
    , data: {
      pageTitle: 'Capture Photo',
      message: fieldLabel,
      pageType: 'CAPTURE_PHOTO',
      site_visit_id: this.site_visit_id
    }
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      this.model.latitude = result.latitude;
      this.model.longitude = result.longitude;
      this.model.timestamp = result.timestamp;
      this.capturedPhotoFromModal = result.image;
      this.uploadSiteVisitImage(result);
    }
  });
}

openPopUpForCaptureImgNL(){
  const fieldLabel = this.findFieldLabelByKey(this.fields, 'cultivation');
  const dialogRef = this.dialog.open(CaptureImageFromCameraComponent, {
    maxWidth: '600px'
    , data: {
      pageTitle: 'Capture Photo',
      message: 'cultivation_seperate_nursery',
      pageType: 'CAPTURE_PHOTO',
      site_visit_id: this.site_visit_id
    }
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      this.model.latitude = result.latitude;
      this.model.longitude = result.longitude;
      this.model.timestamp = result.timestamp;
      this.capturedPhotoFromModal = result.image;
      this.uploadSiteVisitImage(result);
    }
  });
}

convertDataURLToBlob(dataURL: string): Blob {
  const parts = dataURL.split(',');
  const mimeType = parts[0].match(/:(.*?);/)[1];
  const byteString = atob(parts[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }
  return new Blob([uint8Array], { type: mimeType });
}


openPopUpForFinalSubmissionMessage(){
  const dialogRef = this.dialog.open(BasicPopupComponent, {
    maxWidth: '600px'
    , data: {
      pageTitle: 'Success',
      message: 'Thank you for the submission.',
      pageType: 'final_success',
      site_visit_id: this.site_visit_id
    }
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result) {
     
    }
  });
}

goBack(){
  this.router.navigate(['site-visit/'+ this.site_visit_id])
}

}

function cloneDeep<T>(value: T): T {
  // Check if the value is null or not an object/array
  if (value === null || typeof value !== "object") {
    return value;
  }

  // Handle Arrays
  if (Array.isArray(value)) {
    return value.map(item => cloneDeep(item)) as unknown as T;
  }

  // Handle Objects
  const result: Record<string, any> = {};
  for (const key in value) {
    if (value.hasOwnProperty(key)) {
      result[key] = cloneDeep((value as Record<string, any>)[key]);
    }
  }
  return result as T;
}

