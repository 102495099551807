<div class="modal-container">
    <div *ngIf="pageType === 'mother_maiden_name'">
        <div class="modal-header">
        <h3>{{pageTitle}}</h3>
        <button mat-icon-button (click)="close()" class="mb-2">
            <mat-icon>close</mat-icon>
        </button>
        </div>

        <form [formGroup]="formInput">
            <mat-form-field class="w-100">
                <input matInput placeholder="Mother's Maiden Name" formControlName="mother_maiden_name" required> 
                <mat-error *ngIf="formInput.get('mother_maiden_name').errors?.required">
                Enter Mother's Maiden Name</mat-error>
            </mat-form-field>
            
            <button class="blue_button float-end me-3" mat-raised-button type="submit" (click)="submit()">Submit</button>
            <button class="float-end me-4" mat-raised-button (click)="close()">Close</button>
            
        </form>   
    </div>

    <div *ngIf="pageType === 'app_in_progress'">
        <div class="modal-header">
        <h3>{{pageTitle}}</h3>
        <button mat-icon-button (click)="goToHome()" class="mb-2">
            <mat-icon>close</mat-icon>
        </button>
        </div>
        <h4>Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio laboriosam velit quae rerum iste dolorem doloribus, 
            corrupti labore aliquid harum eveniet. Tempore dolore explicabo aliquid ratione perspiciatis debitis expedita atque!</h4>
        <button class="blue_button float-end me-4" mat-raised-button (click)="goToHome()">OK</button>
    </div>


    <div *ngIf="pageType === 'email_verified'">
        <div class="modal-header">
        <h3>{{pageTitle}}</h3>
        <button mat-icon-button (click)="goToHome()" class="mb-2">
            <mat-icon>close</mat-icon>
        </button>
        </div>
        <h4>The verification link has either expired or has been successfully verified.</h4>
        <button class="blue_button float-end me-4" mat-raised-button (click)="goToHome()">OK</button>
    </div>

    <div *ngIf="pageType === 'cancel_application'">
        <div class="modal-header">
        <h3>{{pageTitle}}</h3>
        <button mat-icon-button (click)="close()" class="mb-2">
            <mat-icon>close</mat-icon>
        </button>
        </div>
        <form [formGroup]="cancelForm">
            <mat-form-field class="w-100">
                <textarea class="commentBox" type="textarea" rows="4" #message maxlength="256" formControlName="reason" required></textarea>
                <mat-error *ngIf="cancelForm.get('reason').errors?.required">
                Enter Reason</mat-error>
            </mat-form-field>
            <button class="blue_button float-end me-3" mat-raised-button type="submit" (click)="cancelApp()">Submit</button>
            <button class="float-end me-4" mat-raised-button (click)="close()">Close</button>
            
        </form>   
    </div>

    <div *ngIf="pageType === 'PERMISSION_DENIED'">
        <div class="modal-header">
        <h3>{{pageTitle}}</h3>
        </div>
        <h4>{{message}}</h4>
        <button class="blue_button float-end me-4" mat-raised-button (click)="goToHome()">OK</button>
    </div>
    <div *ngIf="pageType === 'POSITION_UNAVAILABLE'">
        <div class="modal-header">
        <h3>{{pageTitle}}</h3>
        </div>
        <h4>{{message}}</h4>
        <button class="blue_button float-end me-4" mat-raised-button (click)="goToHome()">OK</button>
    </div>
    <div *ngIf="pageType === 'TIMEOUT'">
        <div class="modal-header">
        <h3>{{pageTitle}}</h3>
        </div>
        <h4>{{message}}</h4>
        <button class="blue_button float-end me-4" mat-raised-button (click)="goToHome()">OK</button>
    </div>
    <div *ngIf="pageType === 'Error'">
        <div class="modal-header">
        <h3>{{pageTitle}}</h3>
        </div>
        <h4>{{message}}</h4>
        <button class="blue_button float-end me-4" mat-raised-button (click)="goToHome()">OK</button>
    </div>

    <div *ngIf="pageType === 'FULL_VIEW_IMAGE'">
        <div class="modal-header">
        <h3>{{pageTitle}}</h3>
        <button mat-icon-button (click)="close()" class="mb-2">
            <mat-icon>close</mat-icon>
        </button>
        </div>
        <img class="full-image" src="{{siteImageUrl}}" alt="{{siteImageName}}">
        <button class="blue_button float-end mt-4" mat-raised-button (click)="goToHome()">OK</button>
    </div>

    <div *ngIf="pageType === 'Warning'">
        <div class="modal-header">
        <h3>{{pageTitle}}</h3>
        </div>
        <h4>{{message}}</h4>
        <!-- <button class="blue_button float-end me-4" mat-raised-button (click)="goToHome()">OK</button> -->
    </div>

</div>