import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SiteVisitComponent } from './site-visit/site-visit.component';
import { SiteVisitInspectionComponent } from './site-visit-inspection/site-visit-inspection.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'site-visit/:id',
    component: SiteVisitComponent,
  },
  {
    path: 'site-visit-inspection/:id',
    component: SiteVisitInspectionComponent,
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }