export const environment = {
  production: false,
  name: 'local',
   BACKEND : 'https://api-am-dev.standardc.io/',
  FRONTEND : 'https://am-dev.standardc.io/', 
  //  FRONTEND : 'http://am-test.standardc.io/',
  //IDme Client-id
  client_id : '655e54c855a52bca444898f7ed96e6e9',
  idme_scope: "identity_dev",
  idme_url : 'https://api.idmelabs.com',
  idme_redirect_uri : 'http://am-dev.standardc.io/verification',
  reCaptchaSiteKey:'6Lfz5lMqAAAAAHmYj_tJdy-QWP7iVSh1TiA6iOhy'
};