import { Component, HostListener, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public lat;
  public lng;
  zoom;
  constructor(
    private _api: ApiService
    , private spinner: NgxSpinnerService
    , private cookieService: CookieService
    , private _utilityService: UtilitiesService
  ) { }
  
  ngOnInit(): void {
    this.getLocation();
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 16;
        this.cookieService.set('latitude', this.lat);
        this.cookieService.set('longitude', this.lng);
        console.log("position", position)
      });
    }else{
      console.log("User not allowed")
    }
  }
}
